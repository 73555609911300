import { MouseEventHandler, ReactNode } from 'react';
import MuiLink from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { getAvailableRoutes } from '../../Routes';
import validator from 'validator';
import { useAppTheme } from '@thestudentroom/lib.themes';

interface LinkProps {
    url: string;
    colour?: string;
    children: ReactNode;
    title?: string;
    target?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    onMouseEnter?: MouseEventHandler<HTMLAnchorElement>;
    onMouseLeave?: MouseEventHandler<HTMLAnchorElement>;
    className?: string;
    rel?: string;
}

export default function Link(props: LinkProps) {
    const theme = useAppTheme();
    let routeMatch = false;
    const availableRoutes = getAvailableRoutes();
    for (const route of availableRoutes) {
        if (route == props.url.split('?')[0]) {
            routeMatch = true;
            break;
        }
    }

    if (validator.isURL(props.url) || !routeMatch) {
        // If the URL is a mailto: link, use it as-is
        // If URL does not start with 'https' etc. or '/', prefix with '//' to ensure it directs to the correct website
        const url = props.url.startsWith('mailto:')
            ? props.url
            : validator.isURL(props.url, { require_protocol: true }) ||
              props.url[0] == '/' ||
              !props.url.length
            ? props.url
            : '//' + props.url;

        return (
            <MuiLink
                className={props.className ?? ''}
                underline="hover"
                color={props.colour || 'inherit'}
                href={url}
                title={props.title ?? ''}
                onClick={props.onClick}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
                target={props.target ?? '_self'}
                rel={props.rel}
                sx={{
                    '&:focus-visible': {
                        border: `2px solid ${theme.palette.primary.main}`,
                        borderRadius: `${theme.spacing(2)}`,
                        outline: 'none !important'
                    }
                }}
            >
                {props.children}
            </MuiLink>
        );
    }

    return (
        <MuiLink
            className={props.className}
            underline="hover"
            color={props.colour || 'inherit'}
            to={props.url}
            component={RouterLink as any}
            title={props.title}
            target={props.target}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            rel={props.rel}
        >
            {props.children}
        </MuiLink>
    );
}
