import { useFeatureIsOn } from '@growthbook/growthbook-react';

export default () => {
    let emailSettings = '/profile.php?do=emailoptions';
    if (useFeatureIsOn('new-email-settings')) {
        emailSettings = '/settings/email';
    }
    return [
        {
            index: '1',
            titleKey: 'edit-profile',
            children: [
                {
                    index: '1-1',
                    titleKey: 'edit-my-profile',
                    url: '/profile.php?do=editprofile'
                },
                {
                    index: '1-2',
                    titleKey: 'change-my-avatar',
                    url: '/profile.php?do=editavatar'
                },
                {
                    index: '1-3',
                    titleKey: 'edit-my-login-settings',
                    url: '/profile.php?do=editpassword'
                },
                {
                    index: '1-4',
                    titleKey: 'edit-my-email-settings',
                    url: emailSettings
                },
                {
                    index: '1-5',
                    titleKey: 'edit-my-account-options',
                    url: '/profile.php?do=editoptions'
                },
                {
                    index: '1-6',
                    titleKey: 'edit-my-privacy-settings',
                    url: '/profile.php?do=privacy'
                }
            ]
        },
        {
            index: '3',
            titleKey: 'my-followers',
            url: '/follow.php'
        },
        {
            index: '4',
            titleKey: 'my-ignore-list',
            url: '/profile.php?do=ignorelist'
        },
        {
            index: '5',
            titleKey: 'my-articles',
            url: '/articles'
        },
        {
            index: '6',
            titleKey: 'my-watched-threads',
            url: '/subscription.php'
        },
        {
            index: '7',
            titleKey: 'who-quoted-me',
            url: '/quoted.php'
        },
        {
            index: '8',
            titleKey: 'my-reputation',
            url: '/prevrep.php?do=rec'
        },
        {
            index: '9',
            titleKey: 'my-community-record',
            url: '/record.php'
        },
        {
            index: '10',
            titleKey: 'support',
            url: '/faq.php'
        },
        {
            index: '11',
            titleKey: 'my-reports',
            url: '/reports.php'
        }
    ];
}
