import { useEffect, useState } from 'react'; 
import { useFeatureValue } from '@growthbook/growthbook-react';
import { OwnershipBlock } from '@thestudentroom/lib.education';
import { useSelector } from 'react-redux';
import { runtimeConfig } from '../../../runtimeConfig';
import { RootState } from 'client';
import { getButtons, onLeadGenButtonClick } from '../../LeadGeneration/buttons';

export default () => {
    const [buttons, setButtons] = useState<{type: string, text: string, outcome: string}[]>([]);
    const user = useSelector((state: RootState) => state.user);
    const advertState = useSelector((state: RootState) => state.adverts);

    const buttonOrder: string[] = useFeatureValue('ownership-block-button-order', ['openday', 'prospectus', 'info', 'website']);


    useEffect(() => {
        if (advertState.ownership_learning_provider?.id) {
            setButtons(getButtons(advertState.ownership_learning_provider, buttonOrder));
        } else {
            setButtons([]);
        }
    }, [advertState.ownership_learning_provider?.id]);

    return (
        <OwnershipBlock
            buttons={buttons}
            onButtonClick={(type, outcome) => {
                const buttonOrder = buttons.findIndex(button => button.type == type) + 1;
                onLeadGenButtonClick(user, advertState.ownership_learning_provider?.id, type, outcome, buttonOrder, 'ownership-block');
            }}
            image={advertState.ownership_learning_provider?.content?.Media?.LogoImage ?? {
                url: `${runtimeConfig.publicDomain}/theme/tsr/images/logos/ident-block-colour.svg`,
                alt: advertState.ownership_learning_provider?.name,
                caption: advertState.ownership_learning_provider?.name,
                height: 100,
                width: 182
            }}
            url={`${runtimeConfig.uniGuideDomain}/${advertState.ownership_learning_provider?.slug}`}
            tracking={{
                section: 'header',
                subsection: 'header'
            }}
            hideBorder={true}
            learningProviderId={advertState.ownership_learning_provider?.id}
            reviews={advertState.ownership_learning_provider?.approved_reviews.filter((review: any) => (review?.ReviewOverall?.length ?? 0) > 100)}
            didYouKnows={advertState.ownership_learning_provider?.did_you_know_facts}
            website='TSR'
        />
    )
}
